import React, { ReactElement, useMemo } from 'react';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { CompanyInterface } from '../../../models/companyInterface';
import styles from './companyBox.module.css';
import config from '../../../config';
import LogoBoxDisplay from '../../../components/logoBoxDisplay/logoBoxDisplay';
import { LocalStorageEnum } from '../../../consts/LocalStorageEnum';

const CompanyBox = ({ company }: { company: CompanyInterface }): ReactElement => {
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const companyRoute = useMemo((): string => {
    return config.routes.mainEntity.view.replace(':id', String(company.id));
  }, [company.id]);

  let logo: string = config.logo.logoPlaceholder;

  if (!config.features.useProfilePic && company.companyLogo?.fileId != null) {
    const companyLogo = `${config.fileViewBasePath.replace(
      ':assetId',
      company?.companyLogo?.fileId,
    )}?token=${localStorageFileToken ?? sessionStorageFileToken}`;
    logo = companyLogo;
  }

  if (config.features.useProfilePic && company.profilePicture?.fileId != null) {
    const profilePicture = `${config.fileViewBasePath.replace(
      ':assetId',
      company?.profilePicture?.fileId,
    )}?token=${localStorageFileToken ?? sessionStorageFileToken}`;
    logo = profilePicture;
  }

  return (
    <Link
      to={companyRoute}
      className={cx([styles.root, config.features.useProfilePic && styles.profilePic])}
    >
      <LogoBoxDisplay
        isProfilePic={config.features.useProfilePic}
        isPlaceholder={logo === config.logo.logoPlaceholder}
      >
        <img src={logo} alt={company.companyName} className={styles.logo} />
      </LogoBoxDisplay>
      <h3 className={styles.name}>{company.companyName}</h3>
      {(config.features.displayPositionInField || config.features.displayCompanyInField) && (
        <div className={styles.companyContainer}>
          <div className={styles.name}>{company.companyProfile}</div>
          <div className={styles.name}>{company.missionStatement}</div>
        </div>
      )}
      <div className={styles.location}>
        <EnvironmentOutlined /> <span className={styles.country}>{company.country}</span>
      </div>
    </Link>
  );
};

export default CompanyBox;
