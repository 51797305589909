import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { CompanyInterface } from '../../../models/companyInterface';
import CardTitle from '../../../components/cardTitle/cardTitle';
import CustomTable from '../../../components/customTable/customTable';
import { CustomTableInterface } from '../../../models/customTableInterface';
import config from '../../../config';
import { LocalStorageEnum } from '../../../consts/LocalStorageEnum';
import styles from './aboutTable.module.css';

const AboutTable = ({ company }: { company: CompanyInterface }): ReactElement => {
  const { t } = useTranslation('common');

  const concatenateAddress = useMemo((): string => {
    let address = '';

    if (company.address) {
      address = address + company.address;
    }

    if (company.city) {
      address = address + ', ' + company.city;
    }
    return address;
  }, [company]);

  const aboutData = useMemo((): CustomTableInterface[] => {
    const prepareData: CustomTableInterface[] = [];

    if (company.servicesOffered)
      prepareData.push({ name: t('misc.servicesOffered'), content: company.servicesOffered });

    if (config.features.displayDescriptionInField && company.userDescription)
      prepareData.push({ name: t('misc.userDescription'), content: company.userDescription });

    if (config.features.useMember && company.companyProfile)
      prepareData.push({
        name: t('misc.companyProfile'),
        content: company.companyProfile,
      });

    if (company.missionStatement)
      prepareData.push({
        name: t('misc.missionStatement'),
        content: (
          <>
            {config.features.useProfilePic && company.companyLogo?.fileId && (
              <>
                <img
                  src={`${config.fileViewBasePath.replace(
                    ':assetId',
                    company.companyLogo?.fileId,
                  )}?token=${
                    localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN) ??
                    sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN)
                  }`}
                  alt={company.companyName}
                  className={styles.logoInLine}
                />
                <br />
              </>
            )}
            {company.missionStatement}
          </>
        ),
      });

    if (config.features.displayAddressInField)
      prepareData.push({
        name: t('misc.address'),
        content: concatenateAddress,
      });

    if (config.features.displayAddressInField && company.country)
      prepareData.push({ name: t('misc.country'), content: company.country });

    if (company.philosophy)
      prepareData.push({ name: t('misc.philosophy'), content: company.philosophy });

    if (config.features.establishedInField && company.establishedIn)
      prepareData.push({
        name: t('misc.establishedIn'),
        content: dayjs(company.establishedIn).format(config.dateFormat.year),
      });

    if (!config.features.disableJoinedInField && company.registrationDate)
      prepareData.push({
        name: t('misc.joined'),
        content: dayjs(company.registrationDate).format(config.dateFormat.detailedMonth),
      });

    if (company.vatNo) prepareData.push({ name: t('misc.vatNo'), content: company.vatNo });

    return prepareData;
  }, [company, concatenateAddress, t]);

  if (aboutData.length === 0) return <></>;

  return (
    <>
      <CardTitle type={'bold'} title={t('misc.about')} icon={<InfoCircleOutlined />} />
      <CustomTable data={aboutData} />
    </>
  );
};

export default AboutTable;
