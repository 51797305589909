import React, { ReactElement, ReactNode } from 'react';
import cx from 'classnames';

import styles from './logoBoxDisplay.module.css';

const LogoBoxDisplay = ({
  children,
  isProfilePic,
  isPlaceholder,
}: {
  children: ReactNode;
  isProfilePic?: boolean;
  isPlaceholder?: boolean;
}): ReactElement => {
  return (
    <div
      className={cx(
        styles.root,
        isProfilePic && styles.profilePic,
        isPlaceholder && styles.isPlaceholder,
      )}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default LogoBoxDisplay;
