import React, { ReactElement, useMemo } from 'react';
import { Form, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import FormAccount from './formSections/account';
import styles from './companyForm.module.css';
import FormAbout from './formSections/about';
import FormTeam from './formSections/team';
import TabWrapper from './tabWrapper';
import FormCertificates from './formSections/certificates';
import FormSubmit from './formSections/submit';
import { CompanyInterface } from '../../models/companyInterface';
import useCompanyForm from '../../hooks/useCompanyForm';
import { useUserContext } from '../../context/user.context';
import config from '../../config';
import FormServices from './formSections/servicesTabs/services';
import { ActiveServicesTabsInterface } from '../../models/activeServicesTabs';
import TabKeys from '../../consts/CompanyFormTabsEnum';

export interface FieldError {
  name: Array<string | number>;
  errors: string[];
  warnings: string[];
}

const CompanyForm = ({
  initValues,
  companyId,
}: {
  initValues?: CompanyInterface;
  companyId?: string;
}): ReactElement => {
  const { t } = useTranslation('common');
  const { user } = useUserContext();

  const {
    activeKey,
    changeKey,
    form,
    formValues,
    logoPreviewUrl,
    setLogoPreviewUrl,
    profilePicturePreviewUrl,
    setProfilePicturePreviewUrl,
    setFormValues,
    formErrors,
    submitErrors,
    handleSubmit,
    handleFormChange,
    handleFormErrors,
    loading,
  } = useCompanyForm({ initValues, companyId });

  const selfEditMember = useMemo(() => {
    if (companyId === user.id) return true;
    return false;
  }, [companyId, user.id]);

  const activeServicesTabs: ActiveServicesTabsInterface = useMemo(() => {
    if (selfEditMember && initValues) {
      return {
        memberCategories: initValues.memberCategories,
        partnerCategories: initValues.partnerCategories,
      };
    }
    return {
      memberCategories: formValues.memberCategories,
      partnerCategories: formValues.partnerCategories,
    };
  }, [formValues.memberCategories, formValues.partnerCategories, initValues, selfEditMember]);

  const tabs = useMemo(
    (): TabsProps['items'] => [
      {
        key: TabKeys.ACCOUNT,
        label: t('misc.account'),
        children: (
          <TabWrapper activeKey={activeKey} changeKey={changeKey} boxed>
            <FormAccount
              formValues={formValues}
              isEditCompany={companyId !== undefined}
              selfEditMember={selfEditMember}
            />
          </TabWrapper>
        ),
        forceRender: true,
      },
      {
        key: TabKeys.ABOUT,
        label: t('sidebar.navigation.about'),
        children: (
          <TabWrapper activeKey={activeKey} changeKey={changeKey} boxed>
            <FormAbout
              logoPreviewUrl={logoPreviewUrl}
              setLogoPreviewUrl={setLogoPreviewUrl}
              profilePicturePreviewUrl={profilePicturePreviewUrl}
              setProfilePicturePreviewUrl={setProfilePicturePreviewUrl}
              formValues={formValues}
              form={form}
              setFormValues={setFormValues}
              selfEditMember={selfEditMember}
            />
          </TabWrapper>
        ),
        forceRender: true,
      },
      ...(config.features.nsrnCategories
        ? [
          {
            key: TabKeys.SERVICES,
            label: t('buttons.services'),
            children: (
              <TabWrapper activeKey={activeKey} changeKey={changeKey}>
                <FormServices
                  activeServicesTabs={activeServicesTabs}
                  selfEditMember={selfEditMember}
                  formValues={formValues}
                />
              </TabWrapper>
            ),
            forceRender: true,
          },
        ]
        : []),
      ...(config.features.enableTeam
        ? [
          {
            key: TabKeys.TEAM,
            label: t('sidebar.navigation.team'),
            children: (
              <TabWrapper activeKey={activeKey} changeKey={changeKey}>
                <FormTeam />
              </TabWrapper>
            ),
            forceRender: true,
          },
        ]
        : []),
      {
        key: TabKeys.CERTIFICATES,
        label: t('sidebar.navigation.certificates'),
        children: (
          <TabWrapper activeKey={activeKey} changeKey={changeKey}>
            <FormCertificates formValues={formValues} form={form} setFormValues={setFormValues} />
          </TabWrapper>
        ),
        forceRender: true,
      },
      {
        key: TabKeys.SUBMIT,
        label: t('buttons.submit'),
        children: (
          <TabWrapper activeKey={activeKey} changeKey={changeKey} boxed last>
            <FormSubmit
              form={form}
              formErrors={formErrors}
              submitErrors={submitErrors}
              isEditCompany={companyId !== undefined}
              loading={loading}
            />
          </TabWrapper>
        ),
      },
    ],
    [
      activeKey,
      changeKey,
      companyId,
      form,
      formErrors,
      formValues,
      loading,
      logoPreviewUrl,
      selfEditMember,
      setFormValues,
      setLogoPreviewUrl,
      submitErrors,
      t,
      activeServicesTabs,
    ],
  );

  const renderTabBar = (props: any, DefaultTabBar: any): ReactElement => (
    <DefaultTabBar {...props} className='customTabs' />
  );

  return (
    <div className={styles.root}>
      <Form
        form={form}
        initialValues={formValues}
        onFinish={handleSubmit}
        onValuesChange={handleFormChange}
        onFieldsChange={handleFormErrors}
      >
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          items={tabs}
          onChange={(key: string) => { changeKey(key as TabKeys); }}
          renderTabBar={renderTabBar}
        />
      </Form>
    </div>
  );
};

export default CompanyForm;
